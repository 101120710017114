<template>
    <div id="gene-expression">
        <div class="gene-expression-con">
            <div class="gene-expression-con-id">
                <p>Job id: {{ task.task_id }}</p>
            </div>

            <div class="download">
                <span class="desp-text">
                    gene: {{ task.meta.gene.map(item => item.symbol).join(",") }}
                </span>
                <a-tag color="orange" @click="downloadFile(csvUrl)">
                    <a-icon type="cloud-download"/>
                  download csv
                </a-tag>
            </div>

            <a-table
                    :columns="groupColumns"
                    :data-source="groupData"
                    :loading="tableLoading"
                    rowKey="__EMPTY"
                    :scroll="{ x: true }"
                    :pagination="pagination"
            >
                <div
                        slot="filterDropdown"
                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                        style="padding: 0.5rem"
                >
                    <a-input
                            v-ant-ref="(c) => (searchInput = c)"
                            :placeholder="`Search ${column.title}`"
                            :value="selectedKeys[0]"
                            size="small"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
                    />
                    <a-button
                            type="primary"
                            icon="search"
                            size="small"
                            style="width: 90px; margin-right: 8px"
                            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
                    >Search
                    </a-button>
                    <a-button
                            size="small"
                            style="width: 90px"
                            @click="() => handleReset(clearFilters)"
                    >Reset
                    </a-button>
                </div>
                <a-icon
                        slot="filterIcon"
                        slot-scope="filtered"
                        type="search"
                        :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
                  v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
            >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
                    <template v-else>{{ text }}</template>
                </template>
            </a-table>

<!--            <div class="group-field-value">-->
<!--                <p>分组字段({{task.meta.groupFiled}})值</p>-->
<!--                <div class="group-field-value-con"  ref="groupFieldValueConRef">-->
<!--                    <a-popover v-for="filedValue in Object.keys(groupFiledValue)" :key="filedValue"-->
<!--                               style="max-height: 10vh;">-->
<!--                        <template slot="content">-->
<!--                            <p v-for="item in groupFiledValue[filedValue]">{{ item }}</p>-->
<!--                        </template>-->
<!--                        <div class="group-field-value-con-item">-->
<!--                            {{filedValue}}-->
<!--                        </div>-->
<!--                    </a-popover>-->
<!--                </div>-->
<!--                <div class="group-field-value-overflow" v-if="djzkIsShow" @click="unfoldGroupField">-->
<!--                    点击展开<a-icon type="caret-down" v-if="!unfold" /><a-icon type="caret-up" v-else/>-->
<!--                </div>-->
<!--            </div>-->

            <div style="width: 100%;height: 700px;">
                <iframe :src="heatmapHtml" width="100%" height="100%" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import {parseCsv, downloadFile} from "@/utils/csv.js";
import Chart from "@/components/Chart.vue";
import {fetchSampleList} from "@/request/niu_api";

export default {
    name: "LayoutQueryExpressionOverViewGeneExpression",
    components: {
        Chart
    },
    data() {
        return {
            task: {},
            csvUrl: "",
            heatmapHtml:"",
            groupFiledValue: {},
            groupData: [], //按字段分组的数据
            groupColumns: [],
            pagination: {
                pageSize: 10,
                // current: 1,
                // total: 0,
                showSizeChanger: true,
                showTotal(total) {
                    return `Total ${total} items`
                },
            },
            /* 表格搜索 */
            searchText: "",
            searchInput: null,
            searchedColumn: "",
            /* 表格搜索 End */
            tableLoading: true,

            // 点击展开
            djzkIsShow: false,
            unfold:false
        };
    },
    created() {
        this.task = this.common.getLocalStorageItem("current_gene_expression_task");
        this.csvUrl = this.common.source_url + this.task.result.path + "/data.rpkm.csv";
        this.heatmapHtml = this.common.source_url + this.task.result.path + "/data.heatmap.html";

        // 获取分组
        this.getGroupFiledValue(() => {
            parseCsv(this.csvUrl, (csv) => {
                this.tableLoading = false;

                this.groupData = csv;

                this.groupColumns = this.setColumns(this.groupData, this.searchInput);
            });
        })
    },
    methods: {
        downloadFile,
        async getGroupFiledValue(callback) {
            const res = await fetchSampleList({
                ...this.task.sample_pms,
                page: 1,
                limit: 1000,
                sort: "biosample",
                order: "asc",
            })

            const dataSource = res.data.data;
            let obj = {};
            const groupFiled = this.task.meta.groupFiled;
            dataSource.forEach(ele => {
                if (ele[groupFiled] === "") return;

                const val = ele[groupFiled];

                if (Object.keys(obj).indexOf(val) === -1) {
                    obj[val] = [ele.biosample];
                } else {
                    obj[val].push(ele.biosample);
                }
            })

            //     //过滤分组字段空值
            //     Object.keys(obj).forEach(item => {
            //         if (obj[item].length === 0) {
            //             delete obj[item];
            //         }
            //     })

            this.groupFiledValue = obj;

            this.$nextTick(() => {
                const el = this.$refs.groupFieldValueConRef;
                if(el.clientHeight < el.scrollHeight) {
                    this.djzkIsShow = true;
                }
            })

            callback();
        },
        unfoldGroupField(){
            if(this.unfold){
                this.$refs.groupFieldValueConRef.style.maxHeight = "88px";
                this.unfold = false;
            }else{
                this.$refs.groupFieldValueConRef.style.maxHeight = this.$refs.groupFieldValueConRef.scrollHeight + "px";
                this.unfold = true;
            }
        },
        /**
         * @desc 生成Ant design Vue 表格column
         * @param {Array} data 分组数据
         * @param {*} input 搜索的输入框，用于聚焦
         */
        setColumns(data, input) {
            console.log("setColumns:",data)
            let columns = [];
            Object.keys(data[0]).forEach((ele) => {
                if (ele === "biosample") {
                    columns.push({
                        title: "biosample",
                        dataIndex: "biosample",
                        width: 180,
                        fixed: "left",
                        scopedSlots: {
                            filterDropdown: "filterDropdown",
                            filterIcon: "filterIcon",
                            customRender: "customRender",
                        },
                        onFilter: (value, record) =>
                            record.biosample.toString().toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: (visible) => {
                            if (visible) {
                                setTimeout(() => {
                                    input.focus();
                                }, 100);
                            }
                        },
                    });
                    return;
                }
                /*获取生成热图的y轴End */
                columns.push({
                    title: ele,
                    dataIndex: ele,
                    // width: 110,
                    customRender: (text) => {
                        if (!text) return "";
                        //判断是否是数字
                        return /^[0-9]+.?[0-9]*/.test(text) ? text.toFixed(3) : text;
                    }
                });
            });
            return columns;
        },
        //表格搜索
        handleSearch(selectedKeys, confirm, dataIndex) {
            confirm();
            this.searchText = selectedKeys[0];
            this.searchedColumn = dataIndex;
        },
        //重置搜索
        handleReset(clearFilters) {
            clearFilters();
            this.searchText = "";
        },
    }
};
</script>

<style lang="scss" scoped>
#gene-expression {
    position: relative;
    background: #F9F9FB;
    padding: 1.4rem 8rem;

    .gene-expression-con {
        padding: 2rem;
        background: #fff;

        &-id {
            border-bottom: 1px solid #E6E6E6;
            padding-bottom: 1rem;

            p {
                font-size: 1rem;
                color: #333;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        .download {
            display: flex;
            justify-content: end;
            padding: 40px 0;

            .desp-text {
                font-size: 19px;
                font-weight: 400;
                color: #333333;
                margin-right: 10px;
                line-height: 33px;
            }

            .ant-tag {
                cursor: pointer;
                height: 33px;
                line-height: 33px;
                font-size: 16px;
            }
        }

        .group-field-value{
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;

            &>p{
                height: 23px;
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                line-height: 19px;
            }

            &-con{
                display: flex;
                flex-wrap: wrap;
                max-height: 88px;
                overflow-y: hidden;
                transition: all 0.3s ease-in-out;

                &-item{
                    display: inline-block;
                    background: rgba(9,127,53,0.06);
                    border-radius: 5px 5px 5px 5px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #097F35;
                    height: 32px;
                    line-height: 22px;
                    padding: 5px 10px;
                    margin-right: 20px;
                    margin-bottom: 12px;
                    cursor: pointer;
                }

                &-item-selected{
                    background: #F9F9FB;
                    color: #333333;
                }
            }

            &-overflow{
                cursor: pointer;
                width: 100%;
                height: 33px;
                line-height: 33px;
                background: #F9F9FB;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    ::v-deep(.ant-spin-nested-loading){
        height: 100%;
    }
}
</style>
